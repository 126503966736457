import React, { useState } from "react";
import { Button, Grid, Input, Table } from "semantic-ui-react";
import { round } from "../../utils/integer";

interface IMorgateCalculationRow {
    interestNumber: number;
    monthlyPayment: number,
    principalLeft: number,
    monthlyCapitalRepayment: number,
    monthlyBankInterest: number,
    excessPayment: number,
    overpayMonthFunction: (month: number, price: number) => void;
}

export default function MortageCalculationRow(props: IMorgateCalculationRow) {
    const [overpay, setOverpay] = useState(Number);

    const onOverpayChange = (event: any, data: any) => {
        setOverpay(Number(data.value));
    }

    const onOverpayButtonClick = (event: any, data: any) => {
        props.overpayMonthFunction(props.interestNumber, overpay);
    }

    return (
        <Table.Row>
            <Table.Cell>{round(props.interestNumber)}</Table.Cell>
            <Table.Cell>{round(props.monthlyPayment)}</Table.Cell>
            <Table.Cell>{round(props.monthlyBankInterest)}</Table.Cell>
            <Table.Cell>{round(props.monthlyCapitalRepayment)}</Table.Cell>
            <Table.Cell>{round(props.principalLeft)}</Table.Cell>
            <Table.Cell>{round(props.excessPayment)}</Table.Cell>
            <Table.Cell>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Input type="number" onChange={onOverpayChange} />
                        </Grid.Column>
                        <Grid.Row>
                            <Grid.Column>
                                <Button onClick={onOverpayButtonClick}>Nadpłać</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
        </Table.Row>
    )
}
