import React from "react";
import { Container } from "semantic-ui-react";
import Calculator from "../mortgageCalculator.tsx/calculator";

export default function AppContainer() {
    return (
        <React.Fragment>
            <Container> 
                <Calculator/>
            </Container>
        </React.Fragment>
    )
}