export enum PeriodUnit {
    YEAR = "YEAR",
    MONTH = 'MONTH'
}
export const PeriodUnitDict = [
    {
        key: PeriodUnit.YEAR,
        text: 'Lat',
        value: PeriodUnit.YEAR,
      },
      {
        key: PeriodUnit.MONTH,
        text: 'Miesiecy',
        value: PeriodUnit.MONTH,
      },
]
