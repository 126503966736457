import React, { useState } from "react";
import { Button, Container, Dropdown, Grid, Header, Input, Segment } from "semantic-ui-react";
import { PeriodUnit, PeriodUnitDict } from "../../dictionaries/periodUnit";
import MortageCalculation from "./mortageCalculation";

export default function Calculator() {
    const [amount, setAmount] = useState(Number);
    const [period, setPeriod] = useState(Number);
    const [periodUnit, setPeriodUnit] = useState(PeriodUnit.YEAR);
    const [margin, setMargin] = useState(Number);
    const [wibor, setWibor] = useState(Number);

    const [showResults, setShowResults] = useState(Boolean);

    const onAmountChange = (event: any, data: any) => {
        setAmount(Number(data.value));
    }

    const onPeriodChange = (event: any, data: any) => {
        setPeriod(Number(data.value));
    }

    const onPeriodUnitChange = (event: any, data: any) => {
        setPeriodUnit(data.value);
    }

    const onMarginChange = (event: any, data: any) => {
        setMargin(Number(data.value));
    }

    const onWiborChange = (event: any, data: any) => {
        setWibor(Number(data.value));
    }

    const onCalculateClick = () => {
        setShowResults(false);
        setShowResults(true);
    }

    const onSaveClick = () => {
        localStorage.setItem('credit.param.amount', String(amount));
        localStorage.setItem('credit.param.period', String(period));
        localStorage.setItem('credit.param.periodUnit', String(periodUnit));
        localStorage.setItem('credit.param.margin', String(margin));
        localStorage.setItem('credit.param.wibor', String(wibor));
    }

    const onLoadClick = () => {
        setAmount(Number(localStorage.getItem('credit.param.amount')));
        setPeriod(Number(localStorage.getItem('credit.param.period')));
        setPeriodUnit(localStorage.getItem('credit.param.periodUnit') as PeriodUnit);
        setMargin(Number(localStorage.getItem('credit.param.margin')));
        setWibor(Number(localStorage.getItem('credit.param.wibor')));
    }
 
    return (
        <React.Fragment>
            <Container>
                <Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2">
                                    Parametry kredytu
                            </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid columns="3">
                        <Grid.Row>
                            <Grid.Column>
                                <Input type="number" label="Kwota kretytu" onChange={onAmountChange} value={amount}  />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="number" label="Okres kredytu" onChange={onPeriodChange} value={period} />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown label="Jednostka" options={PeriodUnitDict} onChange={onPeriodUnitChange} value={periodUnit} defaultValue={periodUnit}></Dropdown>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input type="number" label="Marza" onChange={onMarginChange} value={margin} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="number" label="Wibor" onChange={onWiborChange} value={wibor} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Button content="Przelicz" onClick={onCalculateClick}/>
                                <Button content="Zapisz ustawienia" onClick={onSaveClick} />
                                <Button content="Wczytaj ustawienia" onClick={onLoadClick} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {showResults && 
                    <MortageCalculation 
                        amount={amount} 
                        margin={margin}
                        period={period} 
                        periodUnit={periodUnit} 
                        wibor={wibor} />}
            </Container>
        </React.Fragment>
    )
}