import React from "react";
import { Header, Menu } from "semantic-ui-react";

export default function AppHeader() {
    return (
        <React.Fragment>
            <Menu >
                <Header as="h1">
                    Kalkulator Hipoteczny
                </Header>
                <Header as="h3">
                    (development-preview)
                </Header>
            </Menu>
        </React.Fragment>
    )
}