import React from "react";
import { Container, Grid, Segment, Table } from "semantic-ui-react";
import { PeriodUnit } from "../../dictionaries/periodUnit";
import { generateCreditArray, massiveOverpay, overpayMonth, recalculateCreditArray } from "../../services/mortageCalculationService";
import { round } from "../../utils/integer";
import MortageCalculationRow from "./mortageCalculationRow";
import { PaymentMonth } from "../../dto/paymentMonth";
import MassiveOverpayment from "./massiveOverpaymen";

interface IMortageCalculationProps {
    amount: number,
    period: number,
    periodUnit: PeriodUnit,
    margin: number,
    wibor: number,
}

interface IMortageCalculationState {
    creditArray: PaymentMonth[],
    interest: number,
    calculationMonths: number,
    summaryPay: number,
    bankProfit: number,
    initialBankProfit: number,
}

export default class MortageCalculation extends React.Component<IMortageCalculationProps, IMortageCalculationState> {

    private overpayCredit = (month: number, price: number) => {
        const { creditArray, interest, calculationMonths } = this.state;
        const { amount } = this.props;
        let bankProfit = 0;
        let summaryPay = 0;
        overpayMonth(month, price, creditArray!);
        const newCreditArray = recalculateCreditArray(creditArray!, amount, calculationMonths, interest);

        newCreditArray?.forEach((item) => {
            bankProfit += item.monthlyBankInterest;
            summaryPay += item.monthlyPay + item.excessPayment;
        })

        this.setState({ creditArray: newCreditArray, bankProfit, summaryPay });
    };

    constructor(props: IMortageCalculationProps) {
        super(props);
        this.initCreditCalculation(props);
    }

    private initCreditCalculation(props: IMortageCalculationProps) {
        const monthsInYear = 12;
        const calculationCounter = props.periodUnit === PeriodUnit.YEAR ? monthsInYear : 1;
        const calculationMonths = props.period * calculationCounter;
        const interest = (props.margin + props.wibor) / 100;

        const creditArray = generateCreditArray(props.amount, calculationMonths, interest);

        let initialBankProfit = 0;
        let summaryPay = 0;
        creditArray?.forEach((item) => {
            initialBankProfit += item.monthlyBankInterest;
            summaryPay += item.monthlyPay;
        })

        this.state = {
            creditArray,
            interest,
            calculationMonths,
            summaryPay,
            bankProfit: initialBankProfit,
            initialBankProfit,
        }
    }

    private onMassiveOverpayment = (startMonth: number, stopMonth: number, value: number) => {
        const { creditArray, interest, calculationMonths } = this.state;
        const { amount } = this.props;
        let bankProfit = 0;
        let summaryPay = 0;

        console.log(`${startMonth} ${stopMonth} ${value}`)
        massiveOverpay(startMonth-1, stopMonth-1, value, creditArray);
        console.log(creditArray);
        const newCreditArray = recalculateCreditArray(creditArray!, amount, calculationMonths, interest);
        console.log(newCreditArray);
        newCreditArray?.forEach((item) => {
            bankProfit += item.monthlyBankInterest;
            summaryPay += item.monthlyPay + item.excessPayment;
        })


        this.setState({ creditArray: newCreditArray, bankProfit, summaryPay });
    }

    public render() {
        const {
            creditArray,
            interest,
            summaryPay,
            bankProfit,
            initialBankProfit,
        } = this.state;

        return (
            <React.Fragment>
                <Container>
                    <Segment>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    Łączna spłacona kwota: {round(summaryPay)}
                                </Grid.Column>
                                <Grid.Column>
                                    Zarobek Banku: {round(bankProfit)}
                                </Grid.Column>
                                <Grid.Column>
                                    Oszczędziłeś nadpłacając: {round(initialBankProfit - bankProfit)}
                                </Grid.Column>
                                <Grid.Column>
                                    Oprocentowanie: {interest * 100}%
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <MassiveOverpayment onOverpayment={this.onMassiveOverpayment}  />
                    <Segment>
                        <Table unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Numer raty</Table.HeaderCell>
                                    <Table.HeaderCell>Rata</Table.HeaderCell>
                                    <Table.HeaderCell>Odsetki</Table.HeaderCell>
                                    <Table.HeaderCell>Spłata kapitału</Table.HeaderCell>
                                    <Table.HeaderCell>Pozostały kapitał</Table.HeaderCell>
                                    <Table.HeaderCell>Nadpłata</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {creditArray?.map((value, index) =>
                                    <MortageCalculationRow
                                        interestNumber={index + 1}
                                        monthlyPayment={value.monthlyPay}
                                        principalLeft={value.principalLeft}
                                        monthlyBankInterest={value.monthlyBankInterest}
                                        monthlyCapitalRepayment={value.monthlyCapitalRepayment}
                                        excessPayment={value.excessPayment}
                                        overpayMonthFunction={this.overpayCredit} />
                                )
                                }
                            </Table.Body>
                        </Table>
                    </Segment>
                </Container>
            </React.Fragment>
        )
    }

}