import React from 'react';
import './css/App.css';
import AppContainer from './pages/main/container';
import AppFooter from './pages/main/footer';
import AppHeader from './pages/main/header';

function App() {
  return (
    <React.Fragment>
        <AppHeader/>
        <AppContainer/>
        <AppFooter/>
    </React.Fragment>
  );
}

export default App;
