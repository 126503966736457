import { PaymentMonth } from "../dto/paymentMonth";

export function generateCreditArray(kapital: number, liczbaRat: number, oprocentowanie: number): PaymentMonth[] {
    console.log('generate credit array');
    
    const creditArray: PaymentMonth[]= []
    
    for(let i = 0; i < liczbaRat; i++) {
        const installmentNumber = i+1;
        const previousCapital: number = i === 0 ? kapital : creditArray[i-1].principalLeft - creditArray[i-1].excessPayment;
        const monthlyPay = calculateMonthlyPay(kapital, liczbaRat, oprocentowanie);
        const principalLeft = calculateMonthlyLeftPrincipal(previousCapital, liczbaRat, installmentNumber, oprocentowanie);
        const monthlyCapitalRepayment: number = previousCapital - principalLeft;
        const monthlyBankInterest: number = monthlyPay - monthlyCapitalRepayment;

        creditArray.push({
            installmentNumber,
            monthlyPay: monthlyPay,
            principalLeft: principalLeft,
            monthlyCapitalRepayment,
            monthlyBankInterest,
            excessPayment: 0,
        })
    }

    return creditArray;
}

export function overpayMonth(monthNumber: number, price: number, creditArray: PaymentMonth[]) {
    const overpayMonth = creditArray[monthNumber-1];
    overpayMonth.excessPayment = overpayMonth.excessPayment + price;
}

export function massiveOverpay(startMonth: number, stopMonth: number, price: number, creditArray: PaymentMonth[]) {
    for(let i = startMonth; i<=stopMonth; i++) {
        creditArray[i].excessPayment = Number(creditArray[i].excessPayment) + Number(price);
    }
}


export function recalculateCreditArray(creditArray: PaymentMonth[], capital: number, totalInstallmentNumber: number, interest: number) {
    creditArray.forEach((value, i) => {
        if(i!==0) {
            const previousCapital: number = creditArray[i-1].principalLeft - creditArray[i-1].excessPayment;

            const previousExcessPaymentSum = creditArray.slice(0, i).reduce((a, b) => a +b.excessPayment,0)
            const monthlyPay = calculateMonthlyPay(capital-previousExcessPaymentSum, totalInstallmentNumber, interest);
            const principalLeft = calculateMonthlyLeftPrincipal(previousCapital, totalInstallmentNumber, value.installmentNumber, interest);

            const monthlyCapitalRepayment: number = previousCapital - principalLeft;
            const monthlyBankInterest: number = monthlyPay - monthlyCapitalRepayment;

            value.monthlyPay = monthlyPay;
            value.principalLeft = principalLeft;
            value.monthlyCapitalRepayment = monthlyCapitalRepayment;
            value.monthlyBankInterest = monthlyBankInterest;
        } 
    });

    return creditArray;
}

export function calculateMonthlyPay(kapital: number, liczbaRat: number, oprocentowanie: number,) {
    const result = (kapital * oprocentowanie) / (12 * (1-Math.pow((12/(12+oprocentowanie)),liczbaRat)));
    return result;
}

export function calculateMonthlyLeftPrincipal(pozostalyKapital: number, liczbaRat: number, numerRaty: number, oprocentowanie: number) {
    const result =  pozostalyKapital - ((pozostalyKapital * oprocentowanie / 12) / (Math.pow((1 + oprocentowanie / 12) , (liczbaRat - numerRaty + 1)) - 1));
    return result;
}