import React from "react";
import { Accordion, Button, Grid, Icon, Input, Segment } from "semantic-ui-react";

interface IOverpaymentProps {
    onOverpayment: (startMonth: number, stopMonth: number, value: number) => void
}

interface IOverpaymentState {
    startMonth: number,
    stopMonth: number,
    overpaymentValue: number,
    showMassiveOverpayment: boolean
}

export default class MassiveOverpayment extends React.Component<IOverpaymentProps, IOverpaymentState> {
    constructor(props: IOverpaymentProps) {
        super(props);
        this.state = {
            showMassiveOverpayment: false,
            startMonth: 0,
            stopMonth: 0,
            overpaymentValue: 0,
        }
    }


    private handleClickMassiveOverpayment = () => {
        const {showMassiveOverpayment} = this.state;
        this.setState({showMassiveOverpayment: !showMassiveOverpayment})
    }

    private onOveryapaymentStartMonthChange = (event: any, data: any) => {
        this.setState({startMonth: data.value})
    }

    private onOveryapaymentStopMonthChange = (event: any, data: any) => {
        this.setState({stopMonth: data.value})
    }

    private onChangeOverpaymentValue = (event: any, data: any) => {
        this.setState({overpaymentValue: data.value})
    }

    private onOverpaymentAcceptClick = (event: any, data: any) => {
        const {startMonth, stopMonth, overpaymentValue} = this.state;
        this.props.onOverpayment(startMonth, stopMonth, overpaymentValue);
        this.setState({showMassiveOverpayment: false,  startMonth: 0,
            stopMonth: 0,
            overpaymentValue: 0})
    }

    public render() {
        const {
            showMassiveOverpayment
        } = this.state;

        return (
            <React.Fragment>
                    <Segment>
                        <Accordion>
                            <Accordion.Title
                                active={showMassiveOverpayment}
                                index={0}
                                onClick={this.handleClickMassiveOverpayment}
                            >
                                <Icon name='dropdown' />
                            Masowa nadpłata
                            </Accordion.Title>
                            <Accordion.Content active={showMassiveOverpayment}>
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Input type="number" label="Nadpłać od miesiaca" onChange={this.onOveryapaymentStartMonthChange}></Input>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Input type="number" label="Nadpłać do miesiąca" onChange={this.onOveryapaymentStopMonthChange}></Input>
                                        </Grid.Column>
                                        </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Input type="number" label="Kwota" onChange={this.onChangeOverpaymentValue}></Input>
                                        </Grid.Column>
                                        </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button onClick={this.onOverpaymentAcceptClick}>Nadpłać</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                        </Accordion>
                     
                    </Segment>
            </React.Fragment>
        )
    }

}