import React from "react"; 

export default function AppFooter() {
    return (
        <React.Fragment>
            {/* <div className="footer">
                Copyright © 2021 Erwin Brzycki, Agnieszka Brzycka
            </div> */}
        </React.Fragment>
    )
}